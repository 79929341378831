<template>
    <div>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>User Settings</h2>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item active">User Settings</li>
                </ol>
            </nav>
            <br />
            <b-container fluid v-model="page_loader">
                <div v-if="api_error">
                    <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <div v-if="api_success">
                    <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="success"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                    >{{api_success}}</b-alert>
                </div>
                <div class="col-md-8" v-if="user_settings_fields">
                    <div
                        no-body
                        class="mb-1"
                        v-for="(group_name,  group_label) in user_settings_fields"
                        :key=" group_label"
                    >
                        <h5>{{ group_label}}</h5>
                        <b-card-body>
                            <div class="form-group row" v-for="(field, index) in group_name" :key="index">
                                <label class="col-md-3 text-capitalize">{{field.title}}
                                    <span v-if="isRequired(field.validation)" class="text-danger" style="font-weight:bold;">*</span>
                                </label>
                                <div class="col-md-9" v-if="field.type=='text'">
                                    <input type="text" v-model="settings[field.name]" class="form-control" />
                                </div>
                                <div class="col-md-9" v-if="field.type=='radio'">
                                    <span
                                        class="mx-4"
                                        v-for="(radio_option, radio_index) in getFieldOptions(field.validation)"
                                        :key="radio_index"
                                    >
                                        <input
                                            type="radio"
                                            v-model="settings[field.name]"
                                            :name="field.name"
                                            :value="radio_option"
                                            class="form-check-input"
                                        />
                                        <label class="form-check-label text-capitalize">{{radio_option}}</label>
                                    </span>
                                </div>
                                <div class="col-md-9" v-if="field.type=='checkbox'">
                                    <span
                                        class="mx-1"
                                        v-for="(checkbox_option, checkbox_index) in getFieldOptions(field.validation)"
                                        :key="checkbox_index"
                                    >
                                        <b-form-checkbox
                                            v-model="settings[field.name]"
                                            :value="checkbox_option"
                                            :options="checkbox_option"
                                            name="flavour-4a"
                                            size="sm"
                                            inline
                                            class="text-capitalize"
                                        >{{ checkbox_option }}</b-form-checkbox>
                                    </span>
                                </div>
                                <div class="col-md-9" v-if="field.type == 'textarea'">
                                    <textarea class="form-control" v-model="settings[field.name]" rows="4"></textarea>
                                </div>
                                <div class="col-lg-9 col-md-10 col-sm-12" v-if="field.type=='select'">
                                    <select class="form-control text-capitalize" v-model="settings[field.name]">
                                        <option value=""> Select One </option>
                                        <option
                                            v-for="option in getFieldOptions(field.validation)"
                                            :key="option"
                                            :value="option"
                                        >{{option}}</option>
                                    </select>
                                </div>
                            </div>
                        </b-card-body>
                    </div>
                    <div class="form-group row mr-3" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                            type="button"
                            @click="submitUserSettings"
                            class="btn btn-primary text-right m-1"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button type="button" @click="$router.go(-1)" class="btn btn-secondary m-1">Cancel</button>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span>
                        <font-awesome-icon icon="spinner" spin size="2x"></font-awesome-icon>
                    </span>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import NotFound from "../errors/NotFound";
import default_error from "../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            user_settings_fields: null,
            settings: {},
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            page_loader: false,
            api_success: "",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_USER_SETTINGS");
        if(this.access){
            this.getUserSettings();
        }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        isRequired(validation){
            return validation.includes("required");
        },
        getFieldOptions(validation) {
            let array = validation.split('|');
            let options = [];
            array.forEach(data =>{
                if(data.startsWith("in:")) {
                    data = data.split("in:").pop()
                    options = data.split(',');
                }
            });
            return options;
        },
        getUserSettings: function() {
            this.startProgressBar();
            this.page_loader = true;
            let query = this.getAuthHeaders();
            this.axios
                .get(this.$api.get_user_setting_with_fields, query)
                .then(response => {
                    let result = response.data;
                    if (result.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    this.user_settings_fields = result.data[0].user_settings_fields;
                    let settings = result.data[0].user_settings;
                    for (let group_name in this.user_settings_fields) {
                        this.user_settings_fields[group_name].forEach(field => {
                            if (Object.keys(settings).length > 0) {
                                this.settings[field.name] = settings[field.name];
                                if (field.type == "checkbox" && settings[field.name] == null){
                                    this.settings[field.name] = [];
                                } else if(field.type == 'select' && settings[field.name] == null){
                                    this.settings[field.name] = "";
                                }
                            } else if (field.type == "select" && field.default == null) {
                                this.settings[field.name] = "";
                            } else if (field.type == "checkbox" && field.default == null){
                                this.settings[field.name] = [];
                            } else {
                                this.settings[field.name] = field.default;
                            }
                        });
                    }
                    this.page_loader = false;
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (err.response.data.code == 422) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data.message);
                    }
                    this.showAlert();
                    this.page_loader = false;
                    this.updateProgressBar(false);
                });
        },
        submitUserSettings: function() {
            this.startProgressBar();
            this.axios
                .post(this.$api.update_user_settings,{user_settings: this.settings},this.getAuthHeaders())
                .then(response => {
                    if (response.data.error) {
                        this.api_error = response.data.message;
                        this.showAlert();
                        this.updateProgressBar(true);
                        return;
                    }
                    let user_data = this.getUserData();
                    user_data.user_details.user_settings = this.settings;
                    window.$cookies.set("user", user_data, 0);
                    this.api_error = "";
                    this.api_success = response.data.message;
                    this.showAlert();
                    window.scrollTo(0, 0);
                    this.getUserSettings();
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (err.response.data.code == 422) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data.message);
                    }
                    this.api_success = ""
                    this.showAlert();
                    window.scrollTo(0, 0);
                    this.updateProgressBar(false);
                });
        }
    }
};
</script>
